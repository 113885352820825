
import portal from '../images/embers/portal.png'
import money from '../images/embers/money.png'
import chest from '../images/embers/chest.png'
import gem from '../images/embers/gem.png'

const dataAbout = [

    {
        id: 1,
        title : 'Partenaire',
        text : 'Intégrez le Métavers via une collection adaptée.',
        img: portal
    },
    {
        id: 2,
        title : 'Investisseur',
        text : 'Dirigez un village via DAO et obtenez des récompenses.',
        img: money
    },
    {
        id: 3,
        title : 'Joueur',
        text : 'Jouez et obtenez des NFTs échangeables. Progressez et évoluez.',
        img: chest
    },
    {
        id: 4,
        title : 'Tradeur',
        text : 'Profitez des marketplaces pour acheter/revendre les meilleurs objets.',
        img: gem
    }    

]

export default dataAbout;