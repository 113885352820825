import Page404 from "./404";
import Home from "./Home";
import Login from "./Login";
import Logout from "./Logout";
import Register from "./Register";
import Account from "./Account";

const routes = [
  { path: '/', component: Home},
  { path: '/register', component: Register},
  { path: '/login', component: Login},
  { path: '/logout', component: Logout},
  { path: '/my_account', component: Account},
  { path: '/page-404', component: Page404},
]

export default routes;