import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { Autoplay   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";

import axios from 'axios';
import { toast } from 'react-hot-toast';
import Cookie from 'js-cookie';

import data from '../assets/datas/data-collection'

function Register(props) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleRegister = async (event) => {

        event.preventDefault();
        setIsLoading(true);

        if (username && password && email) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, { username, password, email });
    
                if (response.data.error) {
                    toast.error(response.data.error);
                } else {
                    toast.success('Inscription réalisée avec succès, connexion effectuée !');
                    Cookie.set('embersJWT', response.data.token);
                    props.onLoginSuccess();
                }
    
                console.log(response.data);
            } catch (err) {
                toast.error('Erreur, veuillez retenter plus tard...');
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.error('Merci de compléter l\'ensemble des champs.');
            setIsLoading(false);
        }
    }

    return (
        <div>

            <section className="bg-sign-in">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 offset-md-4">
                            <div className="sign-in-form">
                                <h2>Créez votre compte</h2>
                                {props.isLoggedIn === false ? (<>
                                <p>Vous êtes à un clic du monde d'Embers</p>
                                <form>
                                    <fieldset><input id="username" name="username" tabIndex="1" aria-required="true" type="text" placeholder="Votre pseudo" value={username} onChange={(e) => setUsername(e.target.value)} required /></fieldset>
                                    <fieldset> <input id="password" name="password" tabIndex="2" aria-required="true"  type="password" placeholder="Votre mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} required /></fieldset>
                                    <fieldset> <input  name="email" tabIndex="3" aria-required="true"  type="email" placeholder="Votre email" value={email} onChange={(e) => setEmail(e.target.value)} required /></fieldset>
                                    <button className="tf-button submit" onClick={handleRegister} disabled={isLoading}>{isLoading ? 'Création en cours...' : 'Créer mon compte'}</button>
                                </form>
                                <div className="choose-sign">
                                    Vous avez déjà un compte ? <Link to="/login">Connectez-vous !</Link> 
                                </div>
                                </>) : (<p>Vous avez déjà un compte et êtes actuellement connecté dessus</p>) }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default Register;