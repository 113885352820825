import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { toast } from 'react-hot-toast';
import Cookie from 'js-cookie';

const Login = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const navigate = useNavigate();

    useEffect(() => {
        if (props.isLoggedIn === true) { navigate('/my_account'); }
    }, [props]);

    const handleLogin = async (event) => {

        event.preventDefault();
        setIsLoading(true);

        if (username && password) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { username, password });
                
                if (response.data.error) {
                    toast.error(response.data.error);
                } else {
                    toast.success('Connexion réussie !');
                    Cookie.set('embersJWT', response.data.token);
                    props.onLoginSuccess();
                }                
            } catch (err) {
                console.log(err);
                toast.error('Erreur, veuillez retenter plus tard...');
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.error('Merci de compléter l\'ensemble des champs.');
            setIsLoading(false);
        }
    }

    return (<div>
            <section className="bg-sign-in">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 offset-md-4">
                            <div className="sign-in-form">
                                <h2>Connexion</h2>
                                {props.isLoggedIn === false ? (<>
                                <p>Bon retour !</p>
                                <form>
                                    <fieldset><input id="username" name="username" tabIndex="1" aria-required="true" type="text" placeholder="Votre pseudo" value={username} onChange={(e) => setUsername(e.target.value)} required /></fieldset>
                                    <fieldset> <input id="password" name="password" tabIndex="2" aria-required="true"  type="password" placeholder="Votre mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} required /></fieldset>
                                    <div className="forgot-pass-wrap d-none">
                                        <label>
                                        <input type="checkbox" />
                                        <span className="btn-checkbox"></span>
                                        Remember me
                                        </label>
                                        <Link to="#">Forgot your password?</Link>
                                    </div>
                                    <button className="tf-button submit" onClick={handleLogin} disabled={isLoading}>{isLoading ? 'Connexion en cours...' : 'Connexion'}</button>
                                </form>
                                <div className="choose-sign">
                                    Vous n'avez pas encore de compte ? <Link to="/register">Par ici !</Link> 
                                </div>
                                </>) : (<p>Vous êtes déjà connecté à votre compte</p>) }
                            </div>
                        </div>
                    </div>
                </div>
            </section>           
        </div>);
}

export default Login;