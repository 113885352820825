import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import routes from './pages';
import Page404 from './pages/404';
import Header from './components/header/Header';

import Cookie from 'js-cookie';
import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

import { darkTheme } from '@rainbow-me/rainbowkit';
  
const { chains, publicClient } = configureChains(
    [mainnet, polygon],
    [
      publicProvider()
    ]
  );
  
  const { connectors } = getDefaultWallets({
    appName: 'Embers',
    projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
    chains
  });
 
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })

function App() {

    const [formToShow, setFormToShow] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    const handleLoginSuccess = () => {
        setToken(Cookie.get('embersJWT'));
        localStorage.setItem('isLoggedIn', true);
        setIsLoggedIn(true);
        navigate('/my_account');
    }

    const handleLogoutSuccess = () => {
        setToken();
        localStorage.setItem('isLoggedIn', false);
        Cookie.remove('embersJWT');
        setIsLoggedIn(false);
        navigate('/');
    }

    useEffect(() => {

        AOS.init({ duration : 2000 });

        let token = Cookie.get('embersJWT');
        setIsLoggedIn(!!token);
        setToken(token);

        const handleStorageChange = (event) => { if (event.key === 'isLoggedIn') { setIsLoggedIn(event.newValue === 'true'); setToken(Cookie.get('embersJWT')); } };
        window.addEventListener('storage', handleStorageChange);
        return () => { window.removeEventListener('storage', handleStorageChange); };
    }, []);

    const handleButtonClick = () => {
        if (formToShow === 'login') {
            setFormToShow('register');
        } else {
            setFormToShow('login');
        }
    }

    return (
            <WagmiConfig config={wagmiConfig}>
                <RainbowKitProvider chains={chains}  theme={darkTheme({ accentColor: '#c00', accentColorForeground: '#141B22', borderRadius: 'none', })}>
                    <Header isLoggedIn={isLoggedIn} location={location} />
                    <Routes>
                        {
                            routes.map((data,idx) => (
                                <Route key={idx} path={data.path} element={React.createElement(data.component, { token: token, isLoggedIn: isLoggedIn, onLoginSuccess: handleLoginSuccess, onLogoutSuccess: handleLogoutSuccess })} exact />
                            ))
                        }
                        <Route path='*' element={<Page404 />} />
                    </Routes>
                </RainbowKitProvider>
            </WagmiConfig>
    );
}

export default App;
