import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { toast } from 'react-hot-toast';

import { useSignMessage } from 'wagmi'
import { Button, Form } from 'react-bootstrap';
import { encodeAbiParameters, parseAbiParameters } from 'viem'
import { getFunctionSelector, keccak256, toHex, toBytes } from 'viem'

import { useAccount, useNetwork, useSigner, useContractRead, useContractEvent, usePrepareContractWrite, useContractWrite } from 'wagmi';

import jwtDecode from 'jwt-decode';
import Cookie from 'js-cookie';

import './styles.scss';

const WalletRemove = ({gameId, embersJWT, decodedToken, setDecodedToken }) => {
    const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage();
    const { address, isConnected } = useAccount();
    const [ nonce, setNonce ] = useState();
    const [isFormLoading, setIsFormLoading] = useState(false);

    const beginWalletRemove = () => {

        const nonce = Math.floor(Math.random() * 1000000000);

        if (address) {

            const message = keccak256(toBytes(encodeAbiParameters(parseAbiParameters([ 'bytes32', 'address', 'bool', 'address', 'uint256' ]),
            [ keccak256(toHex(gameId)), decodedToken.custodialWallet, false, address, nonce],)));

        setNonce(nonce);
        signMessage({ address, message: { raw: message }, });
    }

    }

    const WalletRemove = async() => {

        setIsFormLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/walletRemove`, { address: address, nonce: nonce, signature: data }, {
                headers: {
                    'Authorization': `Bearer ${embersJWT}`,
                }
              });
            
            if (response.data.error) {
                toast.error(response.data.error);
            } else {
                toast.success('Wallet mis à jour avec succès !');
                Cookie.set('embersJWT', response.data.token);
                setDecodedToken(jwtDecode(response.data.token));
            }
            
            console.log(response.data);
        } catch (err) {
            toast.error('Erreur, veuillez retenter plus tard...');
        }

        setIsFormLoading(false);
    }

    useEffect(() => {
        if (isSuccess == true) WalletRemove().then();
    }, [isSuccess])


    return (<>
        {isConnected ? (<>
        {decodedToken.wallet != decodedToken.custodialWallet ? (<Button className="tf-button wallet-update mb-4" onClick={beginWalletRemove} disabled={isFormLoading}>{isFormLoading ? 'Modification en cours' : 'Repasser sur le wallet custodial'}</Button>) : null }
        </>) : null }
        </>
    )
}

export default WalletRemove;
