import React, { useState, useEffect } from 'react';
import PageTitle from '../components/pagetitle/PageTitle';
import { useNavigate } from 'react-router-dom';

import WalletUpdate from '../components/embers/WalletUpdate'
import WalletRemove from '../components/embers/WalletRemove'

import Footer from '../components/footer';

import img from '../assets/images/slider/slider1.jpg'
import jwtDecode from 'jwt-decode';

function Account(props) {

    const navigate = useNavigate();

    const [decodedToken, setDecodedToken] = useState();

    useEffect(() => {
        if (props.token != null) {
            setDecodedToken(jwtDecode(props.token));
        } else { navigate('/login'); }
    }, [props]);

    return (<>{decodedToken ? (
            <div className='page-collection'>
            <PageTitle title='Mon compte' />
            <section className=" tf-item-detail mb-5">
                <div className="tf-container">
                   <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-image">
                                <img src={img} alt="Embers" />                             
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-inner">
                                <h2 className="title">{decodedToken.username}</h2>
                                <p className="des">Ceci est votre fiche de personnage</p>
                                <div className="infor-item-wrap">
                                    <div className="infor-item-box">
                                        <div className="category">Niveau</div>
                                        <h4 className="name">1</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Objets</div>
                                        <h4 className="name">5</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Classement</div>
                                        <h4 className="name">10/1458</h4>
                                    </div>
                                </div>
                                <div className="group-btn">
                                    {decodedToken.wallet == decodedToken.custodialWallet ? ( <div className="wallet"><p className="text-white">Votre wallet custodial :</p><span className="address">{decodedToken.custodialWallet}</span></div> ) : null }
                                    {decodedToken.wallet != decodedToken.custodialWallet ? ( <div className="wallet"><p className="text-white">Votre wallet :</p><span className="address">{decodedToken.wallet}</span></div> ) : null }
                                    <WalletUpdate gameId="c3d8a19f-507e-4bb1-bc6c-ac4266078495" embersJWT={props.token} decodedToken={decodedToken} setDecodedToken={setDecodedToken} />
                                    <WalletRemove gameId="c3d8a19f-507e-4bb1-bc6c-ac4266078495" embersJWT={props.token} decodedToken={decodedToken} setDecodedToken={setDecodedToken} />
                                </div>
                            </div>
                        </div>
                   </div>
                </div>
            </section>
            <Footer />            
        </div>
        ) : null }
    </>);
}

export default Account;