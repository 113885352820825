import React from 'react';
import dataAbout from '../assets/datas/data-about';
import dataBanner from '../assets/datas/data-banner';
import dataFaqs from '../assets/datas/data-faq';
import dataHowTo from '../assets/datas/data-howto';

import About from '../components/about/About';
import Banner from '../components/banner/Banner';

import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer';
import Logo from '../components/logo/Logo';
import HowTo from '../components/howto/HowTo';

function Home(props) {

    return (
        <div className="home-1">
            <Banner data={dataBanner} />
            <Logo />
            <About data={dataAbout} />
            <HowTo data={dataHowTo} />            
            <Faqs data={dataFaqs} />           
           <Footer />           
        </div>

    );
}

export default Home;