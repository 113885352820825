


const dataFaqs = [
    {
        id: 1,
        title: 'Combien de collections différentes existe-t-il ?',
        text : 'Trois collections distinctes sont en place. La première est conçue pour les projets partenaires, leur offrant un espace "provisoire" dans une ville. La deuxième, destinée aux investisseurs, leur donne non seulement la possibilité de diriger la ville via une DAO, mais également de bénéficier des récompenses. Enfin, la troisième est associée à la Tour Éternelle et comprend tous les objets que l\'on peut découvrir en jeu.'    
    },
    {
        id: 2,
        title: 'Qu\'apporte le statut de "Partenaire" ?',
        text : 'Un partenaire bénéficie d\'un lieu exclusif au cœur d\'une ville. Au-delà de l\'augmentation de visibilité découlant de cette présence, Embers intègre plusieurs fonctionnalités pour offrir aux holdeurs du projet la possibilité d\'interagir de manière innovante.'
    },
    {
        id: 3,
        title: 'Je suis holdeur d\'un projet partenaire, quels sont mes avantages ?',
        text : 'Vous bénéficiez non seulement d\'une interaction innovante avec ce dernier, mais également de bonus précieux pour faire face aux défis de la Tour Éternelle !'
    },
    {
        id: 4,
        title: 'Qu\'offre la collection "Investisseur" d\'Embers ?',
        text : 'Que ce soit la DAO, les récompenses distribuées parmi les divers détenteurs, les loteries, les avantages sont nombreux et variés.'
    },
    {
        id: 5,
        title: 'Qu\'est-ce que la Tour Éternelle ?',
        text : 'C\'est l\'aspect ludique du Métavers. Ici, vous avez la possibilité d\'affronter diverses créatures tout en essayant d\'atteindre le niveau suprême. À chaque partie terminée, vous repartez avec un coffre renfermant des récompenses choisies au hasard.'
    },
    {
        id: 6,
        title: 'Mes NFTs sont dispatchés sur plusieurs wallets, comment faire ?',
        text : 'Via le site d\'Embers, vous avez la possibilité de modifier le portefeuille numérique associé à votre compte à tout instant. Cependant, il est à noter qu\'il n\'est pas possible d\'en lier plusieurs simultanément.'
    },

   
]

export default dataFaqs;