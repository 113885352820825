const menus = [
    {
        id: 1,
        name: 'Accueil',
        links: '/',
        index: 0
    },
    {
        id: 2,
        name: 'Le projet',
        links: '#',
        index: 1
    },
    {
        id: 3,
        name: 'Connexion',
        links: '/login',
        authNeeded: false,
        index: 2
    },
    {
        id: 4,
        name: 'Mon compte',
        links: '/my_account',
        authNeeded: true,
        index: 2
    },
    {
        id: 5,
        name: 'Inscription',
        links: '/register',
        authNeeded: false,
        index: 2
    },
    {
        id: 6,
        name: 'Déconnexion',
        links: '/logout',
        authNeeded: true,
        index: 0
    },
    
]

export default menus;