import React, { useEffect } from 'react';

function Logout(props) {
    useEffect(() => {
        props.onLogoutSuccess();
    }, []);

    return null;
}

export default Logout;