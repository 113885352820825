import React , { useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import Button03 from '../button/Button03';

import logo from '../../assets/images/logo/logo-embers.png';



function Footer(props) {
    
    const year = new Date().getFullYear();
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer">
                <div className="action-box">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="action-box-inner">
                                    <h2 className="title">REJOIGNEZ NOTRE COMMUNAUTÉ</h2>
                                    <p className="content">Gracieusement hébergé par le Discord TheCryptomasksProject !</p>
                                    <div className="group-btn"><Button03 title='DISCORD' path='#' /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-inner">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="widget widget-infor text-center">
                                    <div className="logo">
                                        <img id="logo_footer" src={logo} alt="Binabox" />
                                    </div>
                                    <p className="content">Un monde aux multiples possibilités.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-inner">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bottom">
                                   
                                    <p className="copy-right">© {year} Embers - Made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank">RpGmAx</a></p>
    
                                    <ul className="social-item">
                                        <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                        <li><Link to="#"><i className="fab fa-discord"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
           </footer>
    );
}

export default Footer;