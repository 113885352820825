



import img1 from '../images/embers/feather.png'
import img2 from '../images/svg/icon-wallet-4.svg'
import img3 from '../images/embers/location.png'
import img4 from '../images/embers/victory.png'

const dataWork = [
    {
        id: 1,
        img: img1,
        title: 'CREEZ UN COMPTE',
        text : 'Le monde d\'Embers est à deux clics de souris !',
    },
    {
        id: 2,
        img: img2,
        title: 'RELIEZ VOTRE WALLET',
        text :'Accédez instantanément à des lieux réservés !',
    },

    {
        id: 3,
        img: img3,
        title: 'PARCOUREZ LE MONDE',
        text :'Interagissez avec vos projets préférés et bien plus...',
    },

    {
        id: 4,
        img: img4,
        title: 'JOUEZ',
        text :'Tentez de remporter de fabuleuses récompenses.',
    },

]

export default dataWork;